import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Collection from "@components/partials/StakePage"

const StakePage = () => (
  <Layout
    title="Mark McKenna's Heroes & Villains NFT"
    contentClassName="StakePageContent px-0"
    type="stake"
  >
    <Collection/>
  </Layout>
)

export default StakePage
