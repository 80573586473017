import React, { useState, useEffect, useMemo } from "react"
import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz"
import * as anchor from "@project-serum/anchor"
import Modal from "react-modal"
import axios from "axios"
import { connect } from "react-redux"
import { getStakeNftMetaData } from "@util/dataHandler"
import { stake, unstake, calcBonus } from "@util/stake"
import StakeItem from "./StakeItem"
import { useBonus } from "./useBonus"
import { testWallet } from "@config/config"
import { seekerAddresses } from "@config/seeker"
import "./StakePage.scss"

// const heroBonusUnitPrice = 10
// const villainBonusUnitPrice = 10

const Collection = ({ wallet }) => {
  const rpcHost = process.env.GATSBY_APP_SOLANA_RPC_HOST
  const connection = new anchor.web3.Connection(rpcHost)
  const [provider, setProvider] = useState(null)
  const [loading, setLoading] = useState(false)
  const [nftHeroList, setHeroNftList] = useState([])
  const [nftVillainList, setVillainNftList] = useState([])
  const [heroPassHoldTime, setHeroPassHoldTime] = useState(0)
  const [villainPassHoldTime, setVillainPassHoldTime] = useState(0)
  const [unStakeMintAddr, setUnStakeMintAddr] = useState("")
  const [selectedStakeAccount, setSelectedStakeAccount] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isSeeker, setIsSeeker] = useState(false)
  const [
    refetch,
    fetching,
    isHeroPassholder,
    isVillainPassholder,
    ddStakedCount,
    stakedNFTs,
    stakedHeroNFTs,
    stakedVillainNFTs,
    bonusInfo,
    remains
  ] = useBonus(provider)

  const stakedAmount = useMemo(
    () => stakedHeroNFTs.length + stakedVillainNFTs.length,
    [stakedHeroNFTs, stakedVillainNFTs]
  )

  useEffect(() => {
    if (wallet) {
      setProvider(new anchor.Provider(connection, wallet, "confirmed"))
      setIsSeeker(isSeekerAddress(seekerAddresses, testWallet != "" ? testWallet.toBase58() : wallet.publicKey.toBase58()))
    } else {
      setProvider(null)
      setHeroNftList([])
      setVillainNftList([])
    }
  }, [wallet])

  useEffect(() => {
    if (provider && provider.wallet) {
      getNftList()
      refetch()
    }
  }, [provider])

  const isSeekerAddress = (addressList, walletAddress) => {
    return addressList.some(address => address === walletAddress)
  }

  const getNftList = async () => {
    let tmpInfo = []
    let nfts = await getParsedNftAccountsByOwner({
      publicAddress: testWallet != "" ? testWallet : wallet.publicKey,
      connection: connection,
      serialization: true,
    })
    let evolvedIds = await axios.get(
      "https://api.heroesandvillains.io/origins/fully-evolved"
    )
    nfts.map(info => {
      if (info.data.symbol === "HVORIGINS") {
        let id = Number(info.data.name.split("#").pop())
        if (evolvedIds.data.mints.includes(id) && id != 1) {
          tmpInfo.push({
            id: id,
            mint: info.mint,
          })
        }
      }
    })
    tmpInfo = tmpInfo.sort(sortId)
    setHeroNftList(getStakeNftMetaData(tmpInfo, "Hero"))
    setVillainNftList(getStakeNftMetaData(tmpInfo, "Villain"))
    // bonus testing
    // let hrtime = await getPassTokenHoldTime(nfts, 'HVPASSH1')
    // let vttime = await getPassTokenHoldTime(nfts, 'HVPASSV1')
    // setBonusInfo(await getBonus(provider, getStakeNftMetaData(tmpInfo, "All"), heroBonusUnitPrice, villainBonusUnitPrice, 2, 3))
  }

  const sortId = (a, b) => {
    if (a.id < b.id) {
      return -1
    }
    if (a.id > b.id) {
      return 1
    }
    return 0
  }

  const sortNumberDesc = (a, b) => {
    if (a < b) {
      return 1
    }
    if (a > b) {
      return -1
    }
    return 0
  }

  const covertDate = time => {
    const now = new Date().toLocaleString('en-US', { timeZone: 'UTC' })
    const currentTimeStamp = new Date(now).getTime()
    const date = new Date(currentTimeStamp - time * 1000)
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
    })
  }

  const stakeHandler = async mintAddr => {
    setLoading(true)
    const mintNFTKey = new anchor.web3.PublicKey(mintAddr)
    try {
      await stake(provider, mintNFTKey)
      await new Promise(resolve => setTimeout(resolve, 25000));
      window.location.reload();
      //await getNftList()
      //await refetch()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const unStakeHandler = async () => {
    setIsOpen(false)
    setLoading(true)
    if (unStakeMintAddr !== "") {
      try {
        await unstake(provider, unStakeMintAddr, selectedStakeAccount)
        await new Promise(resolve => setTimeout(resolve, 25000));
        window.location.reload();
        //await getNftList()
        //await refetch()
      } catch (error) {
        console.log(error)
      }
    }
    setLoading(false)
  }

  const closeModalHandler = () => {
    setIsOpen(false)
    setUnStakeMintAddr("")
  }

  const showUnStakeModal = async (mint, stakeAccount) => {
    setSelectedStakeAccount(stakeAccount)
    setUnStakeMintAddr(mint)
    setIsOpen(true)
  }

  const StakedImages = () => {
    let html = []
    for (let i = 0; i < stakedAmount; i++) {
      html.push(
        <img
          src={`/stake_page/staked_${i % 5}.png`}
          key={i}
          className={`w-20 h-20 top-0 absolute transform`}
          style={{ left: i * 20, zIndex: 100 - i }}
          alt="staked"
        />
      )
    }
    return html
  }

  const heroBonus = useMemo(
    () =>
      fetching
        ? "Loading..."
        : calcBonus(
            true,
            testWallet != "" ? testWallet.toBase58() : wallet?.publicKey.toBase58(),
            stakedNFTs,
            stakedHeroNFTs,
            isHeroPassholder,
            ddStakedCount > 0,
            10,
            remains ? remains.hero : 0,
            isSeeker
          ),
    [
      fetching,
      stakedNFTs,
      stakedHeroNFTs,
      isHeroPassholder,
      ddStakedCount,
      remains
    ]
  )

  const villainBonus = useMemo(
    () =>
      fetching
        ? "Loading..."
        : calcBonus(
            false,
            testWallet != "" ? testWallet.toBase58() : wallet?.publicKey.toBase58(),
            stakedNFTs,
            stakedVillainNFTs,
            isVillainPassholder,
            ddStakedCount > 0,
            10,
            remains ? remains.villain : 0,
            isSeeker 
          ),
    [
      fetching,
      stakedAmount,
      stakedVillainNFTs,
      isVillainPassholder,
      ddStakedCount,
      remains
    ]
  )

  return (
    <div className="m-auto stake-container flex flex-col">
      <div className="flex gap-4 w-full lg:flex-row flex-col font-bangers mt-2">
        <div className="hero-stake w-full gap-6 flex flex-col border-b-8 border-t-8 lg:border-r-8  border-black py-8">
          <div className="justify-center gap-4 items-center text-white flex">
            <img src="/stake_page/line.svg" alt="line" className="w-1/4" />
            <p className="text-4xl md:text-5xl xl:text-7xl">$HERO</p>
            <img src="/stake_page/line.svg" alt="line" className="w-1/4" />
          </div>
          <p className="text-sm mini:text-lg sm:text-2xl xl:text-3xl text-white text-center">
            Currently Earned: {heroBonus}
          </p>
          <div className="flex flex-col gap-8 items-center px-4 sm:px-12 lg:px-4">
            {stakedHeroNFTs.map((item, idx) => (
              <StakeItem
                item={item}
                key={idx}
                className="md:w-10/12 lg:w-11/12 w-full"
                eventHandler={(mint, stakeAccount) =>
                  showUnStakeModal(mint, stakeAccount)
                }
                isStaked={true}
              />
            ))}
            <div className="w-full items-center flex justify-center">
              <div className="h-1 bg-gray-200 w-10/12 shadow-md my-2"></div>
            </div>
            {nftHeroList.map((item, idx) => (
              <StakeItem
                item={item}
                key={idx}
                className="md:w-10/12 lg:w-11/12 w-full"
                eventHandler={addr => stakeHandler(addr)}
                isStaked={false}
              />
            ))}
          </div>
        </div>
        <div className="villain-stake w-full gap-6 flex flex-col border-t-8 lg:border-b-8 lg:border-l-8 border-black py-8">
          <div className="justify-center gap-4 items-center text-white flex">
            <img src="/stake_page/line.svg" alt="line" className="w-1/4" />
            <p className="text-4xl md:text-5xl xl:text-7xl">$villain</p>
            <img src="/stake_page/line.svg" alt="line" className="w-1/4" />
          </div>
          <p className="text-sm mini:text-lg sm:text-2xl xl:text-3xl text-white text-center">
            Currently Earned: {villainBonus}
          </p>
          <div className="flex flex-col gap-8 items-center px-4 sm:px-12 lg:px-4">
            {stakedVillainNFTs.map((item, idx) => (
              <StakeItem
                item={item}
                key={idx}
                className="md:w-10/12 lg:w-11/12 w-full"
                eventHandler={(mint, stakeAccount) =>
                  showUnStakeModal(mint, stakeAccount)
                }
                isStaked={true}
              />
            ))}
            <div className="w-full items-center flex justify-center">
              <div className="h-1 bg-gray-200 w-10/12 shadow-md my-2"></div>
            </div>
            {nftVillainList.map((item, idx) => (
              <StakeItem
                item={item}
                key={idx}
                className="md:w-10/12 lg:w-11/12 w-full"
                eventHandler={addr => stakeHandler(addr)}
                isStaked={false}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="bottom-container border-t-8 border-black font-bangers text-white 2xl:p-16 p-4 sm:p-8 mt-6">
        <p className="text-white text-2xl mini:text-3xl sm:text-4xl lg:text-5xl 2xl:text-7xl text-center">
          BONUSES
        </p>
        <div className="grid-cols-2 xl:grid-cols-3 md:grid flex flex-col gap-y-12 gap-x-8 mt-10 pb-10">
          <div className="flex flex-col gap-2">
            <h3 className="text-2xl mini:text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl">
              1. PASS-HOLDERS
            </h3>
            {false && (
              <div className="text-3xl flex text-yellow-100 gap-1 flex-col 2xl:flex-row">
                <div className="flex gap-2">
                  <img
                    src="/stake_page/bonus_check.png"
                    className="w-8 h-8"
                    alt="check"
                  />
                  <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                    Hero Bonus Activated :
                  </p>
                </div>
                <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                  {covertDate(heroPassHoldTime)}
                </p>
              </div>
            )}
            {false && (
              <div className="text-3xl flex text-yellow-100 gap-1 flex-col 2xl:flex-row">
                <div className="flex gap-2">
                  <img
                    src="/stake_page/bonus_check.png"
                    className="w-8 h-8"
                    alt="check"
                  />
                  <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                    villain Bonus Activated :
                  </p>
                </div>
                <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                  {covertDate(villainPassHoldTime)}
                </p>
              </div>
            )}
            <p className="text-brown-800 text-base mini:text-lg sm:text-xl lg:text-2xl xl:text-3xl">
              Receive 2X the normal base rate per staked NFT of the same
              alignment. Multiple passes of the same type, do not stack this
              bonus.
            </p>
            <div className="flex flex-wrap gap-6">
              <div className={`relative w-24`}>
                <img
                  src="/stake_page/hero.png"
                  alt="item"
                  className={`${!isHeroPassholder && "opacity-70"}`}
                />
                {isHeroPassholder && (
                  <img
                    src="/stake_page/selected.png"
                    className="absolute bottom-0 right-0 transform translate-x-1/3 translate-y-0.5 w-5"
                  />
                )}
              </div>
              <div className={`relative w-24`}>
                <img
                  src="/stake_page/villain.png"
                  alt="item"
                  className={`${!isVillainPassholder && "opacity-70"}`}
                />
                {isVillainPassholder && (
                  <img
                    src="/stake_page/selected.png"
                    className="absolute bottom-0 right-0 transform translate-x-1/3 translate-y-0.5 w-5"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-2xl mini:text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl">
              2. seeker
            </h3>
            {false && (
              <div className="text-3xl flex text-yellow-100 gap-1 flex-col 2xl:flex-row">
                <div className="flex gap-2">
                  <img
                    src="/stake_page/bonus_check.png"
                    className="w-8 h-8"
                    alt="check"
                  />
                  <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                    villain Bonus Activated :
                  </p>
                </div>
                <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                  {covertDate(bonusInfo.background.time)}
                </p>
              </div>
            )}
            <p className="text-brown-800 text-base mini:text-lg sm:text-xl lg:text-2xl xl:text-3xl">
              If you stake an NFT from all 15 backgrounds, you will receive a
              very special bonus of 50% to your base rate for all staked NFTs.
            </p>
            <div className="flex flex-wrap gap-6">
              {Object.keys(bonusInfo.background.data).map(key => {
                let active = isSeeker ? true : bonusInfo.background.data[key].isStaked
                let img = bonusInfo.background.data[key].image
                return (
                  <div key={key} className="relative w-18">
                    <img
                      src={`/stake_page/${img}`}
                      alt="item"
                      className={`${!active && "opacity-50"} w-full`}
                    />
                    {active && (
                      <img
                        src="/stake_page/selected.png"
                        className="absolute bottom-0 right-0 transform translate-x-1/3 translate-y-0.5 w-5"
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-2xl mini:text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl">
              3. racial equality
            </h3>
            {false && (
              <div className="text-3xl flex text-yellow-100 gap-1 flex-col 2xl:flex-row">
                <div className="flex gap-2">
                  <img
                    src="/stake_page/bonus_check.png"
                    className="w-8 h-8"
                    alt="check"
                  />
                  <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                    Bonus Activated:{" "}
                  </p>
                </div>
                <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                  {covertDate(bonusInfo.race.time)}
                </p>
              </div>
            )}
            <p className="text-brown-800 text-base mini:text-lg sm:text-xl lg:text-2xl xl:text-3xl">
              Stake an NFT of each base race (human, elf, alien, demon,
              celestial and merfolk), you will receive a 25% bonus to your base
              rate for all staked NFTs.
            </p>
            <div className="flex flex-wrap gap-4">
              {Object.keys(bonusInfo.race.data).map(key => {
                let active = bonusInfo.race.data[key].isStaked
                let img = bonusInfo.race.data[key].image
                return (
                  <div key={key} className="relative w-20">
                    <img
                      src={`/stake_page/${img}`}
                      alt="item"
                      className={`${!active && "opacity-50"} w-full`}
                    />
                    {active && (
                      <img
                        src="/stake_page/selected.png"
                        className="absolute bottom-0 right-0 transform translate-x-1/3 translate-y-0.5 w-5"
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-2xl mini:text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl">
              4. duality
            </h3>
            {false && (
              <div className="text-3xl flex text-yellow-100 gap-1 flex-col 2xl:flex-row">
                <div className="flex gap-2">
                  <img
                    src="/stake_page/bonus_check.png"
                    className="w-8 h-8"
                    alt="check"
                  />
                  <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                    Bonus Activated:
                  </p>
                </div>
                <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                  {covertDate(bonusInfo.duality.time)}
                </p>
              </div>
            )}
            <p className="text-brown-800 text-base mini:text-lg sm:text-xl lg:text-2xl xl:text-3xl">
              If you stake both a Hero and a Villain, you will receive a 10%
              bonus to your base rate for all staked NFTs regardless of
              alignment.
            </p>
            <div className="flex flex-wrap gap-6">
              {Object.keys(bonusInfo.duality.data).map(key => {
                let active = bonusInfo.duality.data[key].isStaked
                let img = bonusInfo.duality.data[key].image
                return (
                  <div key={key} className="relative w-20">
                    <img
                      src={`/stake_page/${img}`}
                      alt="item"
                      className={`${!active && "opacity-50"} w-full`}
                    />
                    {active && (
                      <img
                        src="/stake_page/selected.png"
                        className="absolute bottom-0 right-0 transform translate-x-1/3 translate-y-0.5 w-5"
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex flex-col gap-2 overflow-hidden">
            <h3 className="text-2xl mini:text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl">
              5. The More, The Merrier
            </h3>
            {false && (
              <div className="text-3xl flex text-yellow-100 gap-1 flex-col 2xl:flex-row">
                <div className="flex gap-2">
                  <img
                    src="/stake_page/bonus_check.png"
                    className="w-8 h-8"
                    alt="check"
                  />
                  <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                    Bonus Activated:
                  </p>
                </div>
                <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                  -
                </p>
              </div>
            )}
            <p className="text-brown-800 text-base mini:text-lg sm:text-xl lg:text-2xl xl:text-3xl">
              Pre-Aug 7th: 5% compound bonus per NFT. Post-Aug 7th: 0-50 NFTs = 10X; 51-100 NFTs = 25X; 101+ NFTs = 50X multiplier.
            </p>
            <div className="flex flex-wrap gap-6">
              <div className="flex items-center relative w-full">
                {stakedAmount > 0 ? (
                  <StakedImages />
                ) : (
                  <img
                    src={`/stake_page/staked_0.png`}
                    className={`w-20 h-20 top-0 absolute transform`}
                    style={{ left: 0, zIndex: 100 }}
                    alt="staked"
                  />
                )}
                <div
                  className="absolute flex flex-col items-center justify-center left-2 text-white top-1 transform"
                  style={{ zIndex: 101 }}
                >
                  <p className="text-4xl">{stakedAmount}</p>
                  <p className="text-2xl">STAKED</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-16 md:mt-0">
            <h3 className="text-2xl mini:text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl">
              6. dapper duck bonus
            </h3>
            {false && (
              <div className="text-3xl flex text-yellow-100 gap-1 flex-col 2xl:flex-row">
                <div className="flex gap-2">
                  <img
                    src="/stake_page/bonus_check.png"
                    className="w-8 h-8"
                    alt="check"
                  />
                  <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                    Bonus Activated:
                  </p>
                </div>
                <p className="text-base mini:text-lg sm:text-xl lg:text-2xl">
                  april 6 9:23 am
                </p>
              </div>
            )}
            <p className="text-brown-800 text-base mini:text-lg sm:text-xl lg:text-2xl xl:text-3xl">
              10% staking bonus has been granted to those eligible and is now discontinued.
            </p>
            <div className="flex flex-wrap gap-6">
              <div className="relative w-20">
                <img
                  src={`/stake_page/dapper_duck.png`}
                  alt="item"
                  className={`${
                    ddStakedCount === 0 && "opacity-50"
                  } w-full`}
                />
                {(ddStakedCount > 0) && (
                  <img
                    src="/stake_page/selected.png"
                    className="absolute bottom-0 right-0 transform translate-x-1/3 translate-y-0.5 w-5"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModalHandler}
        className="unstaking-modal"
      >
        <div className="w-4/5 sm:w-2/3 md:w-1/2 text-center m-auto py-10">
          <div className="font-bangers text-xl sm:text-2xl">
            By unstaking this NFT, you will lose any unclaimed tokens it has
            accrued. In addition, you may no longer qualify for certain bonuses.
            Are you sure you wish to proceed?
          </div>
          <div className="flex items-center justify-center mt-5">
            <div className="w-1/2 sm:w-1/3">
              <div className="btn btn-yes" onClick={unStakeHandler}></div>
            </div>
            <div className="w-1/2 sm:w-1/3">
              <div className="btn btn-no" onClick={closeModalHandler}></div>
            </div>
          </div>
        </div>
      </Modal>
      {loading && (
        <div className="loading-panel">
          <div className="loading-effect">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  )
}

const stateProps = state => ({
  wallet: state.wallet,
})

export default connect(stateProps, null)(Collection)
