import React, { useState } from "react"

const StakeItem = props => {
  const { className, item, eventHandler, isStaked } = props
  const [load, setLoad] = useState(false)
  const [error, setError] = useState(false)
  const imageUrl = item.metadata.thumb_image

  const convertDate = time => {
    const now = new Date().toLocaleString('en-US', { timeZone: 'UTC' })
    const currentTimeStamp = new Date(now).getTime()
    const date = new Date(currentTimeStamp - time * 1000)
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
    })
  }

  return (
    <div
      className={`flex justify-between items-center font-bangers text-white ${className}`}
    >
      <div className="flex gap-2">
        {!load ? (
          <img
            src="/gallery_page/loading.png"
            className="animate-pulse fw-8 h-8 mini:w-14 mini:h-14 sm:w-16 sm:h-16 xl:w-21 xl:h-21"
            alt="nft item"
          />
        ) : (
          error && (
            <img
              src="/gallery_page/default.png"
              className="bg-black w-8 h-8 mini:w-14 mini:h-14 sm:w-16 sm:h-16 xl:w-21 xl:h-21"
              alt="nft item"
            />
          )
        )}
        <img
          src={imageUrl}
          className={`w-8 h-8 mini:w-14 mini:h-14 sm:w-16 sm:h-16 xl:w-21 xl:h-21 ${
            load && !error ? "" : "hidden"
          } border-2 ${isStaked ? "border-green-500" : "border-red-500"}`}
          onLoad={() => {
            setLoad(true), "loaded"
          }}
          onError={() => {
            setError(true), setLoad(true)
          }}
          alt="nft item"
        />

        <div className="flex flex-col">
          <p className="text-xl mini:text-2xl sm:text-4xl xl:text-5xl">
            {item.metadata.name}
          </p>
          {isStaked && (
            <p className="text-sm mini:text-lg sm:text-2xl xl:text-3xl opacity-70">
              STAKED SINCE : {convertDate(item.time)}
            </p>
          )}
        </div>
      </div>
      <button
        // className={`${className}`}
        className="relative w-3/12"
        onClick={() => eventHandler(item.mint, item.stakeAccount)}
      >
        {!isStaked ? (
          <img src="/stake_page/btn_stake.png" className="w-full" alt="stake" />
        ) : (
          <img
            src="/stake_page/btn_unstake.png"
            className="w-full"
            alt="unstake"
          />
        )}
        <p className="absolute transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 sm:text-3xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
          {!isStaked ? "Stake" : "Unstake"}
        </p>
      </button>
    </div>
  )
}

export default StakeItem
